const worksElements = document.querySelectorAll(`.works__box`);
const worksPreview = document.querySelector(`#worksPreview`);

for (let i = 0; i < worksElements.length; i++) {
  const elem = worksElements[i];
  elem.addEventListener("click", function () {
    deleteClass();
    this.classList.add("active");
    worksPreview.src = this.getAttribute("data-image");
  });
}

const deleteClass = () => {
  for (let i = 0; i < worksElements.length; i++) {
    worksElements[i].classList.remove("active");
  }
};
