const nameInput = document.querySelector("#askName");
const emailInput = document.querySelector("#askEmail");
const notesInput = document.querySelector("#askText");
const btn = document.querySelector("#askBtn");

const validationEmail = (value) => {
  if (value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/)) {
    return true;
  } else {
    return false;
  }
};

btn.addEventListener("click", function (e) {
  let emailSuccess = false;
  let nameSuccess = false;
  let notesSuccess = false;
  e.preventDefault();
  if (nameInput.value.length > 0) {
    nameSuccess = true;
    nameInput.closest(`.started__field`).classList.remove("error");
  } else {
    nameSuccess = false;
    nameInput.closest(`.started__field`).classList.add("error");
  }

  if (validationEmail(emailInput.value)) {
    emailSuccess = true;
    emailInput.closest(`.started__field`).classList.remove("error");
  } else {
    emailSuccess = false;
    emailInput.closest(`.started__field`).classList.add("error");
  }

  if (notesInput.value.length > 0) {
    notesSuccess = true;
    notesInput.closest(`.started__field`).classList.remove("error");
  } else {
    notesSuccess = false;
    notesInput.closest(`.started__field`).classList.add("error");
  }

  if (nameSuccess && emailSuccess && notesSuccess) {
    console.log("success");
  } else {
    console.log("no");
  }
});
